import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateUser, deactivateUser, getUsers, SetCurrentUser } from "../middleware/action-creators/usersActionsCreators";
import { ACCOUNT_TYPES, numberWithCommas } from "../middleware/APIs/commonApis";
import { CoursesHeader, GlobalLoader } from "./commonEmbeds";


const Clients = () => {
    const {
        usersReducer: {
            loggedInUser, currentUser, usersSmallLoader, userResponseMessage, userResponseSuccess, users, userWaitingForResponse,
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();


    React.useEffect(() => {
        document.title = "Students | Ekozi";
        gettingUsers();
    }, []);

    function gettingUsers(appending = false) {
        let params = { accountType: ACCOUNT_TYPES.CLIENT };

        if (appending) {
            params["start"] = users.length;
        }

        dispatch(getUsers(params, appending));

    };

    function edittingUser(status) {

        let payload = currentUser;

        payload["edited_by"] = loggedInUser.ID ?? 1;

        if (status === "active") {
            dispatch(deactivateUser(payload));
        } else {
            dispatch(activateUser(payload));
        }

    }

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 15;

    // Calculate pagination details
    const totalPages = Math.ceil(users.length / usersPerPage);

    // Create a new reversed array without mutating the original
    const reversedUsers = [...users].reverse();

    // Get paginated users based on the current page and per page size
    const paginatedUsers = users.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="d-flex flex-row justify-content-between">
            <div className=" col-lg-6 col-md-6 col-sm-6 my-2 p-3 bg-white text-start">
                <CoursesHeader menu={{ name: "Students" }} numberOfUsers={users.length} />

                {
                    userWaitingForResponse ? (
                        <GlobalLoader />
                    ) : (
                        <>
                            <table className="table bg-white rounded table-hover border table-responsive">
                                <thead className="text-black">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedUsers.map((item, i) => (
                                        <tr onClick={() => dispatch(SetCurrentUser(item))} key={i}>
                                            <th scope="row">
                                                {numberWithCommas(
                                                    (currentPage - 1) * usersPerPage + i + 1
                                                )}
                                            </th>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item?.mobile || "-"}</td>
                                            <td
                                                className={`text-uppercase ${item.account_status.toLowerCase() === "active"
                                                    ? "text-success"
                                                    : "text-warning"
                                                    }`}
                                            >
                                                {item.account_status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            <div className="d-flex justify-content-center mt-3">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`btn ${currentPage === index + 1 ? "btn-primary" : "btn-outline-primary"
                                            } mx-1`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </>
                    )
                }

            </div>


            <div className="col-lg-4 col-md-5 col-sm-6 p-2">

                {
                    currentUser !== null ?

                        <div className="whiteElevation p-3 text-start">

                            <div className="h5">User Details</div>
                            <div className="row">
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={currentUser.first_name} readOnly />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" value={currentUser.last_name} readOnly />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                    <input type="email" className="form-control" value={currentUser.email} readOnly />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Mobile</label>
                                    <input type="email" className="form-control" value={currentUser?.mobile} readOnly />
                                </div>
                            </div>
                            {!userResponseSuccess && <div className="text-danger mt-2">{userResponseMessage}</div>}

                            <>
                                {
                                    usersSmallLoader ? <div className="mt-3"><GlobalLoader /></div> :
                                        <div onClick={() => edittingUser(currentUser.account_status.toLowerCase())}
                                            className={"cursor text-center rounded mt-4 py-2 text-white " + (currentUser.account_status.toLowerCase() === "active" ? "bg-danger" : "bg-success")} >
                                            <span>{currentUser.account_status.toLowerCase() === "active" ? "DISABLE" : "ACTIVATE"}</span>
                                        </div>}
                            </>

                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default Clients;