import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable, Footer, GlobalLoader, Header, SideBar } from "../components/commonEmbeds";
import { getCourses } from "../middleware/action-creators/coursesActionCreators";
import { getTransactions } from "../middleware/action-creators/transactionsActionCreators";
import { APICALLLIMIT, formatDate, numberWithCommas } from "../middleware/APIs/commonApis";


const Transactions = () => {

    const { coursesReducer: {
        courses, lastCourseCount, coursesWaitingForResponse, coursesResponseMessage,
        pullMoreCoursesLoader, videos,
    },
        transactionsReducer: { transactions, transactionResponseMessage, lastTransactionsPullCount, transactionWaitingForResponse, pullMoreTransactionsLoader },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    React.useEffect(() => {

        gettingCourses();
        gettingTransactions();
    }, []);

    function gettingCourses(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, sortByPopularity: "yes" };

        if (appending) {
            params["start"] = courses.length;
        }

        dispatch(getCourses(params, appending));
    };

    function gettingTransactions(appending = false) {
        let params = {};
        // let params = { start: 0, count: APICALLLIMIT };

        if (appending) {
            params["start"] = videos.length;
        }

        dispatch(getTransactions(params, appending));
    };


    const [currentPage, setCurrentPage] = useState(1);
    const txnsPerPage = 12;

    // Calculate pagination details
    const totalPages = Math.ceil(transactions.length / txnsPerPage);
    const paginatedTransactions = transactions.reverse().slice(
        (currentPage - 1) * txnsPerPage,
        currentPage * txnsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const CoursesTHeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col text-start">Course Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Purchased count</th>
        </tr>
    </thead>

    const CoursesTBody = <tbody>
        {
            courses && courses.map((item, i) => {
                return (
                    <tr className="cursor" key={i}>
                        <th scope="row">{i + 1}.</th>
                        <td className="col-3 text-start">{item.name.en}</td>
                        <td>{numberWithCommas(item.price)}</td>
                        <td className="">{item.purchases_count}</td>
                    </tr>
                )
            })
        }
    </tbody>

    const TransactionsTHeader = <thead className="text-black">
        <tr>
            <th scope="col">ID</th>
            <th scope="col">From</th>
            <th scope="col">Amount</th>
            <th scope="col">Transaction type</th>
            <th scope="col">Status</th>
        </tr>
    </thead>

    const TransactionsTBody = <tbody>
        {
            transactions && paginatedTransactions.map((item, i) => {
                return (
                    <tr className="cursor" key={i}>
                        <th scope="row">
                            {item.ID}
                        </th>
                        <th scope="row" className="text-left">
                            {item.mobile}
                            <div>
                                <small>{formatDate(item.UpdatedAt, true, true)}</small>
                            </div>
                        </th>
                        <td className="col-3">{`${item.currency} ${numberWithCommas(item.amount)}`}</td>
                        <td>{item.transaction_type}</td>
                        <td className={item.status.toUpperCase() === "FAILED" ? "text-danger" : item.status.toUpperCase() === "PROCESSING" ? "text-warning" : "text-success"}>{item.status}</td>
                    </tr>
                )
            })
        }
    </tbody>


    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <div className="row mt-2">
                        <div className="col-sm">
                            <p className="h5">Transactions</p>
                            {
                                transactionWaitingForResponse ? <GlobalLoader /> :
                                    courses.length === 0 ? <span className="h6 text-danger">{transactionResponseMessage}</span> :
                                        <div className="" style={{ paddingTop: 10 }}>
                                            <CustomTable tableHeader={TransactionsTHeader} tableBody={TransactionsTBody} />

                                            {/* Pagination */}
                                            <div className="d-flex justify-content-center mt-3">
                                                {Array.from({ length: totalPages }, (_, index) => (
                                                    <button
                                                        key={index}
                                                        onClick={() => handlePageChange(index + 1)}
                                                        className={`btn ${currentPage === index + 1 ? "btn-primary" : "btn-outline-primary"
                                                            } mx-1`}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                            }
                        </div>

                        <div className="col-sm">
                            <p className="h5">Top bought courses</p>
                            {
                                coursesWaitingForResponse ? <GlobalLoader /> :
                                    courses.length === 0 ? <span className="h6 text-danger">{coursesResponseMessage}</span> :
                                        <div className="" style={{ paddingTop: 10 }}>
                                            <CustomTable tableHeader={CoursesTHeader} tableBody={CoursesTBody} />

                                            {lastCourseCount === APICALLLIMIT && (
                                                <div className="text-center">
                                                    {pullMoreCoursesLoader ? <GlobalLoader /> :
                                                        <span onClick={() => gettingCourses(true)} className="loadMore">Load more</span>}
                                                </div>
                                            )}
                                        </div>
                            }
                        </div>

                    </div>
                </section>
            </section>

            <Footer />

        </section>
    )
}

export default Transactions;