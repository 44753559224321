/* eslint-disable default-case */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCourse, deleteCourse, editCourse, getCourse, getCourses, getSections, SuccessfulAction } from "../middleware/action-creators/coursesActionCreators";
import { CoursesHeader, CustomTable, GlobalLoader } from "./commonEmbeds";
import { ACCOUNT_TYPES, APICALLLIMIT, ASSETS_BASE_URL, breakUpFile, numberWithCommas } from "../middleware/APIs/commonApis";
import { getCategories, getSkills } from "../middleware/action-creators/categoriesActionCreators";
import { Link } from "react-router-dom";
import { getUsers } from "../middleware/action-creators/usersActionsCreators";

const difficulties = [
    { name: "Beginner", value: "Beginner" },
    { name: "Intermediate", value: "Intermediate" },
    { name: "Expert", value: "Expert" }
];

let newCourse = {
    name: { en: "", sw: "" },
    photo: "",
    preview_video: { en: "", sw: "" },
    outcome: { en: "abc", sw: "abc" },
    what_you_will_learn: [],
    requirements: [],
    includes: [],
    curriculum: [],
    description: { en: "", sw: "" },
    categories: [],
    skills: [],
    difficulty: "",
    featured: false, best_seller: false, on_sale: false,
    price: 0, sale_price: 0,
    discount_ends: "",
    status: "",
    content_creator: 0,
    content_creator_name: "",
    course_length: "",
    free_course: false
};

const CoursesComponent = ({ menu }) => {
    const { coursesReducer: {
        courses, lastCourseCount, coursesWaitingForResponse, coursesResponseMessage, pullMoreCoursesLoader, currentCourse, courseAdded
    },
        categoriesReducer: { skills, categories },
        usersReducer: { loggedInUser, users },
    } = useSelector((state) => state);

    const [reload, setReloading] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {

        dispatch(getUsers({ accountType: ACCOUNT_TYPES.CONTENT_CREATOR }));
        gettingCourses();
        dispatch(getCategories({}));
        dispatch(getSkills({}));
        dispatch(getSections({}));
    }, []);


    React.useEffect(() => {
        if (currentCourse !== null) {
            newCourse = JSON.parse(JSON.stringify(currentCourse));
            setReloading(!reload);
        }
    }, [currentCourse]);

    React.useEffect(() => {

        if (courseAdded === true) {
            document.getElementById('courseForm').reset();
            newCourse = {
                name: { en: "", sw: "" },
                photo: "",
                preview_video: { en: "", sw: "" },
                what_you_will_learn: [],
                requirements: [],
                includes: [],
                curriculum: [],
                description: { en: "", sw: "" },
                categories: [],
                skills: [],
                difficulty: "",
                featured: false, best_seller: false, on_sale: false,
                price: 0, sale_price: 0,
                discount_ends: "",
                status: "",
                content_creator: 0,
                content_creator_name: '',
                course_length: "",
                free_course: false
            };
            setReloading(!reload);
        }
    }, [courseAdded]);

    const THeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Featured</th>
            <th scope="col">Best seller</th>
            <th scope="col">Rate</th>
            <th scope="col">Videos</th>
            <th scope="col">Revenue Generated</th>
            <th scope="col">Edit</th>
            <th scope="col text-danger">Delete</th>
        </tr>
    </thead>

    const TBody = <tbody>
        {
            courses && courses.map((item, i) => {
                return (
                    <tr className="cursor" key={item.ID}>
                        <th scope="row">{i + 1}.</th>
                        <td className="col-3">{item.name.en}</td>
                        <td>{numberWithCommas(item.price)}</td>
                        <td className={!item.featured ? "text-secondary" : "text-success"}>{item.featured ? "TRUE" : "FALSE"}</td>
                        <td className={!item.best_seller ? "text-secondary" : "text-success"}>{item.best_seller ? "TRUE" : "FALSE"}</td>
                        <td>{item.rate}</td>
                        <td className="link-primary"><Link to={"/video/" + item.ID}>{"Videos"}</Link></td>
                        <td>{numberWithCommas(item.revenue_generated)}</td>
                        <td className=""><i onClick={() => gettingACourse(item.ID)} title="Edit" className="fa-solid fa-pen-to-square text-warning"></i></td>
                        <td className="">
                            {/* <i onClick={() => deleteACourse(i)} className="fa-solid fa-pen-to-square text-warning"></i> */}
                            <div title={"Delete " + item.name.en} className="text-danger cursor"
                                onClick={() => deleteACourse(i)}
                            >
                                <i className="fa-solid fa-trash-can"></i>
                                <span className="ms-1"> <u>Delete</u></span>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    </tbody>


    function gettingCourses(appending = false) {
        let params = { start: 0, count: APICALLLIMIT };

        if (appending) {
            params["start"] = courses.length;
        }

        dispatch(getCourses(params, appending));
    }

    function gettingACourse(courseID) {

        dispatch(getCourse({ courseID }));
    }

    function createCourse(e) {
        e.preventDefault();

        let payload = newCourse;

        if (payload.on_sale) {
            payload["discount_ends"] = newCourse.tarehe + " " + newCourse.time;
            payload["sale_price"] = Number(newCourse.sale_price);
        }

        payload["price"] = Number(newCourse.price);
        payload["added_by"] = loggedInUser.ID

        dispatch(addCourse(payload));
    };

    function inputChange(key, value, i) {
        let found = false;

        switch (key) {
            case "price":
            case "sale_price":
                newCourse[key] = Number(value);
                break;
            case "content_creator":
                newCourse[key] = users[value].ID;
                newCourse["content_creator_name"] = users[value].first_name + " " + users[value].last_name;
                break;
            case "free_course":
                newCourse[key] = value;
                newCourse['price'] = 0;
                break;
            case "difficulty":
            case "on_sale":
            case "featured":
            case "course_length":
                newCourse[key] = value;
                break;
            case "tarehe":
            case "time":
                newCourse[key] = value;
                break;
            case "name":
            case "description":
                newCourse[key]["en"] = value;
                break;
            case "preview_video":
                newCourse[key]["en"] = value;
                break;
            case "includes":
            case "curriculum":
            case "requirements":
            case "what_you_will_learn":
                newCourse[key][i].en = value;
                break;

            case "categories":

                const tmp = { ID: categories[i].ID, name: { en: categories[i].name } };

                if (newCourse[key].length > 0) {
                    for (let x = 0; x < newCourse[key].length; x++) {
                        if (newCourse[key][x].ID === value.ID) {
                            newCourse[key].splice(x, 1);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        newCourse[key].push(tmp);
                        break;
                    };

                } else {
                    newCourse[key].push(tmp);
                }
                break;
            case "skills":
                if (newCourse[key].length > 0) {
                    for (let x = 0; x < newCourse[key].length; x++) {
                        if (newCourse[key][x].ID === value.ID) {
                            newCourse[key].splice(x, 1);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        newCourse[key].push(skills[i]);
                        break;
                    };
                } else {
                    newCourse[key].push(skills[i]);
                }

        }

        setReloading(!reload);

    };

    function addNewRow(key) {
        newCourse[key].push({ en: "", sw: "" });
        setReloading(!reload);
    }

    const deleteACourse = (index) => {
        if (window.confirm("Are you sure you want to delete this Course?")) {
            let tmpCats = courses.slice();
            tmpCats[index].updated_by = loggedInUser.ID
            dispatch(deleteCourse(tmpCats[index]));
        }
    };

    function sendingFile(file) {
        newCourse["photo"] = file;

        setReloading(!reload);
    };


    function deleteThisRow(index, key) {
        newCourse[key].splice(index, 1);
        setReloading(!reload);
    }

    const clearCourseForm = () => {

        document.getElementById('courseForm').reset();
        newCourse = {
            name: { en: "", sw: "" },
            photo: "",
            preview_video: { en: "", sw: "" },
            what_you_will_learn: [],
            requirements: [],
            includes: [],
            curriculum: [],
            description: { en: "", sw: "" },
            categories: [],
            skills: [],
            difficulty: "",
            featured: false, best_seller: false, on_sale: false,
            price: 0, sale_price: 0,
            discount_ends: "",
            status: "",
            content_creator: 0,
            content_creator_name: '',
            course_length: "",
            free_course: false
        };
        dispatch(SuccessfulAction("GET_COURSE_SUCCESS", null, null));

    }

    return (
        <div className="text-start mt-3">

            <CoursesHeader menu={menu} />

            <div className="rowBtn align-items-start">
                {
                    coursesWaitingForResponse ? <GlobalLoader /> :
                        courses.length === 0 ? <span className="h6 text-danger">{coursesResponseMessage}</span> :
                            <div className="col-lg-6 col-md-5 col-sm-12" style={{ paddingTop: 10 }}>
                                <CustomTable tableHeader={THeader} tableBody={TBody} />

                                {lastCourseCount === APICALLLIMIT && (
                                    <div className="text-center">
                                        {pullMoreCoursesLoader ? <GlobalLoader /> :
                                            <span onClick={() => gettingCourses(true)} className="loadMore">Load more</span>}
                                    </div>
                                )}
                            </div>
                }


                {

                    currentCourse === null ?
                        <NewCourse
                            inputChange={inputChange}
                            addNewRow={addNewRow}
                            createCourse={createCourse}
                            newCourse={newCourse}
                            deleteThisRow={deleteThisRow}
                            breakUpFile={breakUpFile}
                            sendingFile={sendingFile}
                        />
                        :
                        <ViewCourse
                            inputChange={inputChange}
                            addNewRow={addNewRow}
                            newCourse={newCourse}
                            clearCourseForm={clearCourseForm}
                            deleteThisRow={deleteThisRow}
                            breakUpFile={breakUpFile}
                            sendingFile={sendingFile}
                        />
                }
            </div>

            <br />
            <br />
            <br />
        </div>
    );
};



const NewCourse = ({ newCourse, inputChange, deleteThisRow, createCourse, addNewRow, breakUpFile, sendingFile }) => {
    const { coursesReducer: { coursesResponseMessage, coursesResponseSuccess,
        coursesSmallLoader
    },
        categoriesReducer: { skills, categories, skillsWaitingForResponse, categoryWaitingForResponse },
        usersReducer: { users, userWaitingForResponse },
    } = useSelector((state) => state);

    return (
        <div className="col-lg-5 col-md-5 col-sm-12 whiteElevation">
            <div className="h5">Add a new course</div>
            {coursesSmallLoader && <GlobalLoader />}
            <form id="courseForm" className="form" onSubmit={createCourse}>
                <div className={coursesResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{coursesResponseMessage}</div>
                <input type="text" className="form-control mb-2" placeholder="Course name" name="names" onChange={(e) => inputChange("name", e.target.value)} required />
                <textarea type="text" className="form-control  mb-2" placeholder="Description" name="descriptions" onChange={(e) => inputChange("description", e.target.value)} required />
                {/* <textarea type="text" className="form-control  mb-2" placeholder="Outcomes" name="outcome" onChange={(e) => inputChange("outcome", e.target.value)} required /> */}

                <div className="my-2">
                    <label>What you will learn</label>
                    {
                        newCourse.what_you_will_learn.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" placeholder="" name="what_you_will_learn" onChange={(e) => inputChange("what_you_will_learn", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "what_you_will_learn")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("what_you_will_learn")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                </div>

                <div className="my-2">
                    <label>Curriculum</label>
                    {
                        newCourse?.curriculum?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" placeholder="" name="curriculum" onChange={(e) => inputChange("curriculum", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "curriculum")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("curriculum")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                </div>


                <div className="my-2">
                    <label>This course includes</label>
                    {
                        newCourse?.includes?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" placeholder="" name="includes" onChange={(e) => inputChange("includes", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "includes")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("includes")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                </div>

                <div className="my-2">
                    <label>Requirements</label>
                    {
                        newCourse?.requirements?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" placeholder="" name="requirements" onChange={(e) => inputChange("requirements", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "requirements")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("requirements")} className="col-3 editBtn mb-2 col-1 mt-0">Add New</span>
                </div>

                <div>
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select an image (landscape)</div>
                    <div className="logoImageBg">
                        <img src={newCourse.photo === "" ? "assets/img/placeholder.png" : (ASSETS_BASE_URL + newCourse.photo)} alt="Logo" className="img-fluid" style={{ width: 300, height: 200 }} />
                    </div>
                    <input type="file" onChange={(e) => breakUpFile(e, sendingFile)} className="my-2" id="img" accept="image/*" />
                </div>


                <div>
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Preview video link</div>
                    <input type="text" onChange={(e) => inputChange("preview_video", e.target.value)} className="my-2" placeholder="Preview video" />
                </div>

                <span className="" style={{ fontSize: 14, color: "#000000" }}>Course length</span>
                <div className="">
                    <input type="text" value={newCourse.course_length} onChange={(e) => inputChange("course_length", e.target.value)} className="my-2" />
                </div>


                <div className="mb-2">
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select categories</div>
                    <div className="d-flex flex-row  flex-wrap">
                        {
                            categoryWaitingForResponse ? <GlobalLoader /> : categories.map((item, i) => (
                                <div key={i} className="d-flex flex-row align-items-center justify-content-center me-2">
                                    <input
                                        type="checkbox"
                                        // checked={false}
                                        onChange={() => inputChange("categories", item, i)}
                                    />
                                    <span className="ms-1">{item?.name}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="mb-2">
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select skills</div>
                    <div className="d-flex flex-row  flex-wrap">
                        {
                            skillsWaitingForResponse ? <GlobalLoader /> : skills.map((item, i) => (
                                <div key={i} className="d-flex flex-row align-items-center justify-content-center me-2">
                                    <input
                                        type="checkbox"
                                        onChange={() => inputChange("skills", item, i)}
                                    // checked={false}
                                    />
                                    <span className="ms-1">{item?.name.en}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>


                <>
                    <label>Difficulty</label>
                    <select id="sub" name="difficulty" onChange={(e) => inputChange("difficulty", e.target.value)} required className="mb-2 col-12" >
                        <option value="" hidden>{"Select difficulty"}</option>
                        {
                            difficulties.map((level, i) => (
                                <option key={i} value={level.value}>{level.name}</option>
                            ))
                        }
                    </select>
                </>
                {
                    userWaitingForResponse ?
                        <i className={"fa-solid fa-circle-notch fa-spin fa-2x text-white"}></i> :
                        <>
                            <label>Instructor</label>

                            <select id="sub" name="content_creator" onChange={(e) => inputChange("content_creator", e.target.value)} required className="mb-2 col-12" >
                                <option value="" hidden>{"Select Instructor"}</option>
                                {
                                    users.map((user, index) => (
                                        <option key={user.ID} value={index}>{`${user.first_name} ${user.last_name}`}</option>
                                    ))
                                }
                            </select>
                        </>
                }


                <div className="col-md-6 d-flex align-items-center mb-2">
                    <label className="me-2">Free Course</label>
                    <input type="checkbox" className="" checked={newCourse.free_course} name="free_course" onChange={() => inputChange("free_course", !newCourse.free_course)} />
                </div>
                {
                    newCourse?.free_course ? null :

                        <>
                            <div className="col-md-6 d-flex align-items-center mb-2">
                                <label className="me-2">On sale?</label>
                                <input type="checkbox" className="" checked={newCourse.on_sale} name="on_sale" onChange={() => inputChange("on_sale", !newCourse.on_sale)} />
                            </div>

                            <div className="rowBtn">
                                <input type="number" className="form-control mb-2" placeholder="Price" name="price" onChange={(e) => inputChange("price", e.target.value)} required />
                                <div className="col-1"></div>
                                {newCourse.on_sale && (
                                    <input type="number" className="form-control mb-2" placeholder="Sale price" name="sale_price"
                                        onChange={(e) => inputChange("sale_price", e.target.value)} required />)}
                            </div>

                        </>
                }



                {
                    !newCourse?.free_course && newCourse.on_sale && (
                        <div className="rowBtn">
                            <div className="col-3">
                                <span>Sale end date</span>
                                <input type="date" className="col-10 mb-2" name="date" onChange={(e) => inputChange("tarehe", e.target.value)} required />
                            </div>

                            <div className="col-1"></div>
                            <div className="col-2">
                                <span>Sale end time</span>
                                <input type="time" className="col-12 mb-2 " name="time" onChange={(e) => inputChange("time", e.target.value)} required />
                            </div>
                        </div>
                    )
                }


                <div className="col-md-6 d-flex align-items-center mb-2">
                    <label className="me-2">Featured?</label>
                    <input type="checkbox" className="" checked={newCourse.featured} name="featured" onChange={() => inputChange("featured", !newCourse.featured)} />
                </div>

                <button type="submit" className="editBtn col-2 cursor btn text-white" style={{ height: 40 }}>{coursesSmallLoader ? <i className={"fa-solid fa-circle-notch fa-spin fa-2x text-white"}></i> : "Submit"}</button>
                <div className={coursesResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{coursesResponseMessage}</div>
            </form>
        </div>
    )
}



const ViewCourse = ({ newCourse, inputChange, deleteThisRow, breakUpFile, clearCourseForm, addNewRow, sendingFile }) => {
    const { coursesReducer: {
        coursesResponseMessage, coursesResponseSuccess,
        coursesSmallLoader,
    },
        categoriesReducer: { skills, categories, skillsWaitingForResponse, categoryWaitingForResponse },
        usersReducer: { loggedInUser, users, userWaitingForResponse },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    function edittingCourse() {

        let payload = newCourse;

        if (payload.on_sale) {
            if (newCourse?.tarehe) {
                payload["discount_ends"] = newCourse.tarehe + " " + newCourse.time;
            }
            payload["sale_price"] = Number(newCourse.sale_price);
        }
        payload["updated_by"] = loggedInUser.ID

        dispatch(editCourse(payload));
    };

    const getInstructorNameById = (instructorID) => {
        const instructor = users.find(instructor => instructor.ID === instructorID);

        if (instructor) {
            return `${instructor.first_name} ${instructor.last_name}`;
        }
        return '';
    };


    function compareInArray(key, ID) {


        switch (key) {
            case "skills":
                for (let x = 0; x < newCourse.skills.length; x++) {
                    if (newCourse.skills[x].ID === ID) {
                        return true;
                    }
                };
                break;
            case "categories":
                for (let x = 0; x < newCourse.categories.length; x++) {
                    if (newCourse.categories[x].ID === ID) {
                        return true;
                    }
                }
        }

        return false;

    };



    const instructorName = getInstructorNameById(newCourse?.content_creator);
    return (
        <div className="col-lg-5 col-md-6 col-sm-12 whiteElevation">

            <div className="rowBtn">
                <div className="h5">Edit a Course</div>
                {coursesSmallLoader && <GlobalLoader />}
                <i title="Close" className="fa-solid fa-circle-xmark fa-xl text-danger cursor"
                    onClick={clearCourseForm}></i>
            </div>
            <form id="courseForm" className="form">
                <>
                    <label>Course name</label>
                    <input type="text" className="form-control mb-2" value={newCourse.name.en} onChange={(e) => inputChange("name", e.target.value)} required />
                </>

                <>
                    <label>Course description</label>
                    <textarea type="text" className="form-control  mb-2" value={newCourse.description.en} onChange={(e) => inputChange("description", e.target.value)} required />

                </>
                {/* <>
                                        <label>Course outcomes</label>
                                        <textarea type="text" className="form-control  mb-2" value={newCourse.outcome.en} onChange={(e) => inputChange("outcome", e.target.value)} required />
                                    </> */}

                <div className="my-2">
                    <label>What you will learn</label>
                    {
                        newCourse.what_you_will_learn.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" value={req.en} onChange={(e) => inputChange("what_you_will_learn", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "what_you_will_learn")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("what_you_will_learn")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                </div>

                <div className="my-2">
                    <label>Curriculum</label>
                    {
                        newCourse?.curriculum?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" value={req.en} onChange={(e) => inputChange("curriculum", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "curriculum")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("curriculum")} className="editBtn mb-2 col-3 mt-0">Add New</span>
                </div>

                <div className="my-2">
                    <label>This course includes</label>
                    {
                        newCourse?.includes?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" value={req.en} onChange={(e) => inputChange("includes", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "includes")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("includes")} className="editBtn mb-2 col-3 mt-0">Add New</span>
                </div>

                <div className="my-2">
                    <label className="">Requirements</label>
                    {
                        newCourse?.requirements?.map((req, i) => (
                            <div className="d-flex flex-row align-items-center" key={i}>
                                <span className="me-1">{i + 1}.</span>
                                <input type="text" className="form-control  mb-2" value={req.en} onChange={(e) => inputChange("requirements", e.target.value, i)} required />
                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "requirements")} />
                            </div>
                        ))
                    }
                    <span onClick={() => addNewRow("requirements")} className="editBtn mb-2 col-3 mt-0">Add New</span>
                </div>

                <div>
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select an image (landscape)</div>
                    <div className="logoImageBg">
                        <img src={newCourse.photo === "" ? "assets/img/placeholder.png" : (ASSETS_BASE_URL + newCourse.photo)} alt="Logo" className="img-fluid" style={{ width: 300, height: 200 }} />
                    </div>
                    <input type="file" onChange={(e) => breakUpFile(e, sendingFile)} className="my-2" id="img" accept="image/*" />
                </div>


                <div>
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Preview video link</div>
                    <input type="text" onChange={(e) => inputChange("preview_video", e.target.value)} className="my-2" value={newCourse.preview_video.en} />
                </div>

                <span className="" style={{ fontSize: 14, color: "#000000" }}>Course length</span>
                <div className="">
                    <input type="text" value={newCourse.course_length} onChange={(e) => inputChange("course_length", e.target.value)} className="my-2" />
                </div>



                <div className="mb-2">
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select categories</div>
                    <div className="d-flex flex-row  flex-wrap">
                        {
                            categoryWaitingForResponse ? <GlobalLoader /> : categories.map((item, i) => (
                                <div key={i} className="d-flex flex-row align-items-center justify-content-center me-2">
                                    <input
                                        type="checkbox"
                                        checked={compareInArray("categories", item.ID)}
                                        onChange={() => inputChange("categories", item, i)}
                                    />
                                    <span className="ms-1">{item?.name}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="mb-2">
                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select skills</div>
                    <div className="d-flex flex-row  flex-wrap">
                        {
                            skillsWaitingForResponse ? <GlobalLoader /> : skills.map((item, i) => (
                                <div key={i} className="d-flex flex-row align-items-center justify-content-center me-2">
                                    <input
                                        type="checkbox"
                                        onChange={() => inputChange("skills", item, i)}
                                        checked={compareInArray("skills", item.ID)}
                                    />
                                    <span className="ms-1">{item?.name.en}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <>
                    <label>Difficulty</label>
                    <select id="sub" name="difficulty" onChange={(e) => inputChange("difficulty", e.target.value)} required className="mb-2 col-12" >
                        <option value="" hidden>{newCourse.difficulty}</option>
                        {
                            difficulties.map((level, i) => (
                                <option key={i} value={level.value}>{level.name}</option>
                            ))
                        }
                    </select>
                </>
                {
                    userWaitingForResponse ?
                        <i className={"fa-solid fa-circle-notch fa-spin fa-2x text-white"}></i> :
                        <>
                            <label>Instructor</label>
                            <select id="sub" name="content_creator"
                                onChange={(e) => inputChange("content_creator", e.target.value)} required className="mb-2 col-12" >
                                <option value="" hidden>{instructorName}</option>
                                {
                                    users.map((user, index) => (
                                        <option key={user.ID} value={index}>{`${user.first_name} ${user.last_name}`}</option>
                                    ))
                                }
                            </select>
                        </>
                }

                <div className="col-md-6 d-flex align-items-center mb-2">
                    <label className="me-2">Free Course</label>
                    <input type="checkbox" className="" checked={newCourse.free_course} name="free_course" onChange={() => inputChange("free_course", !newCourse.free_course)} />
                </div>
                {
                    newCourse?.free_course ? null :

                        <>
                            <div className="col-md-6 d-flex align-items-center mb-2">
                                <label className="me-2">On sale?</label>
                                <input type="checkbox" className="" checked={newCourse.on_sale} name="on_sale" value={newCourse.on_sale} onChange={() => inputChange("on_sale", !newCourse.on_sale)} />
                            </div>

                            <div className="rowBtn">
                                <input type="number"
                                    className="form-control mb-2"
                                    placeholder="Price" name="price"
                                    value={newCourse?.price}
                                    onChange={(e) => inputChange("price", e.target.value)} required />
                                <div className="col-1"></div>
                                {newCourse.on_sale && (
                                    <input type="number" className="form-control mb-2" placeholder="Sale price" name="sale_price"
                                        value={newCourse?.sale_price}
                                        onChange={(e) => inputChange("sale_price", e.target.value)} required />)}
                            </div>

                        </>
                }

                {
                    !newCourse?.free_course && newCourse.on_sale && (
                        <div className="rowBtn">
                            <div className="col-3">
                                <span>Sale end date</span>
                                <input type="date" className="col-10 mb-2"
                                    value={newCourse?.tarehe || newCourse?.discount_ends?.split(" ")[0] || ""}
                                    onChange={(e) => inputChange("tarehe", e.target.value)} required />
                            </div>

                            <div className="col-1"></div>
                            <div className="col-2">
                                <span>Sale end time</span>
                                <input type="time" className="col-12 mb-2 "
                                    value={newCourse?.time || newCourse?.discount_ends?.split(" ")[1]?.slice(0, 5) || ""}
                                    onChange={(e) => inputChange("time", e.target.value)} required />
                            </div>
                        </div>
                    )
                }


                <div className="col-md-6 d-flex align-items-center mb-2">
                    <label className="me-2">Featured?</label>
                    <input type="checkbox" className="" checked={newCourse.featured} onChange={() => inputChange("featured", !newCourse.featured)} />
                </div>

                <button onClick={edittingCourse} className="editBtn col-3 cursor btn text-white" style={{ height: 40 }}>{coursesSmallLoader ? <GlobalLoader color="text-white" /> : "Edit"}</button>
                <div className={coursesResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{coursesResponseMessage}</div>

            </form>
        </div>
    )
}
export default CoursesComponent;