import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser, deleteUser, editUser, getUserPrivileges, getUsers } from "../middleware/action-creators/usersActionsCreators";
import { ACCOUNT_TYPES, numberWithCommas, randomPasswordGenerator } from "../middleware/APIs/commonApis";
import { CoursesHeader, GlobalLoader } from "./commonEmbeds";

const Administrators = () => {

    const {
        usersReducer: {
            loggedInUser, userPrivileges, usersSmallLoader, userResponseMessage, userResponseSuccess, users, userWaitingForResponse,
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [activeUser, setActiveUser] = React.useState(null);
    const [currentID, setCurrentID] = React.useState(null);


    React.useEffect(() => {
        document.title = "Adminstrators | Ekozi";
        if (loggedInUser === null) return;
        if (userPrivileges.length === 0) {
            dispatch(getUserPrivileges());
        }
        gettingUsers();
    }, []);


    function gettingUsers(appending = false) {
        let params = { accountType: ACCOUNT_TYPES.ADMIN };

        if (appending) {
            params["start"] = users.length;
        }

        dispatch(getUsers(params, appending));

    };

    function inputChange(key, value) {

        setActiveUser(prevState => ({
            ...prevState, [key]: value
        }));

    };


    function editAdmin() {
        dispatch(editUser(activeUser));
    }

    function addingAdmin(e) {
        e.preventDefault();

        let form = {},

            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        };

        const tmpPass = randomPasswordGenerator();
        form["channel"] = "direct";
        form["account_type"] = ACCOUNT_TYPES.ADMIN;
        form["password"] = tmpPass;
        form["added_by"] = loggedInUser.ID ?? 1;
        form["confirm_password"] = tmpPass;
        form["account_status"] = "active";

        dispatch(addUser(form));
    }

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 15;

    // Calculate pagination details
    const totalPages = Math.ceil(users.length / usersPerPage);

    // Create a new reversed array without mutating the original
    const reversedUsers = [...users].reverse();

    // Get paginated users based on the current page and per page size
    const paginatedUsers = users.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Soft delete staff member
    const handleDelete = (item) => {
        const confirmed = window.confirm(`Are you sure you want to delete ${item.first_name} ${item.last_name}?`);
        if (confirmed) {
            setCurrentID(item.ID); // Set the current user ID being deleted
            dispatch(deleteUser(item)); // Dispatch the delete action
        }
    };

    return (
        <div className="d-flex flex-row justify-content-between">
            <div className=" col-lg-6 col-md-6 col-sm-6 my-2 p-3 bg-white text-start">
                <CoursesHeader menu={{ name: "Administrators" }} numberOfUsers={users.length} />

                {/* <div className="h5">Administrators</div> */}
                {

                    userWaitingForResponse ? <GlobalLoader /> :
                        <table className="table bg-white rounded table-hover border table-responsive">
                            <thead className="text-black">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    paginatedUsers.map((item, i) => (
                                        <tr onClick={() => setActiveUser(item)} className="cursor" key={i}>
                                            <th scope="row">{numberWithCommas((currentPage - 1) * usersPerPage + i + 1)}</th>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td className="text-capitalize">{item.role_name}</td>
                                            <td
                                                className="pointer"
                                                title={`Delete ${item.first_name}`}
                                                onClick={() => handleDelete(item)}
                                            >
                                                {usersSmallLoader && item.ID === currentID ? (
                                                    <i className="fa fa-spinner fa-spin text-secondary"></i> // Loader icon
                                                ) : (
                                                    <i className="fa fa-trash-can text-danger"></i> // Delete icon
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                }

                {/* Pagination */}
                <div className="d-flex justify-content-center mt-3">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`btn ${currentPage === index + 1 ? "btn-primary" : "btn-outline-primary"
                                } mx-1`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>


            <div className="col-lg-4 col-md-5 col-sm-6 p-2">

                {
                    activeUser !== null ?

                        <div className="p-3 text-start whiteElevation">

                            <div className="h5">Edit user</div>
                            <div className="row">
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={activeUser.first_name} onChange={(e) => inputChange("first_name", e.target.value)} />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" value={activeUser.last_name} onChange={(e) => inputChange("last_name", e.target.value)} />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                    <input type="email" className="form-control" value={activeUser.email} onChange={(e) => inputChange("email", e.target.value)} />
                                </div>
                                {/* <div className="col-md-6 mt-md-0 mt-3"> <label>Phone Number</label>
                                    <input type="tel" className="form-control" value={activeUser.phone} onChange={(e) => inputChange("phone", e.target.value)} /> </div> */}
                            </div>
                            <div className=" my-md-2 my-3 pt-2"> <label>Role</label>
                                <select id="sub" onChange={(e) => inputChange("role", e.target.value)} >
                                    <option value="" hidden>{activeUser.role_name}</option>
                                    {
                                        userPrivileges.map((role, i) => (
                                            <option key={i} value={role.ID}>{role.rank}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className={userResponseSuccess ? "text-success" : "text-danger"}>{userResponseMessage}</div>
                            <div className="d-flex justify-content-center">
                                {
                                    usersSmallLoader ?
                                        <GlobalLoader />
                                        :
                                        <button onClick={editAdmin} className="btn editBtn mt-3 px-5">Edit</button>
                                }
                            </div>
                        </div>
                        :
                        <div className="p-3 text-start whiteElevation">
                            <div className="h5">Add new administrator</div>
                            <form className="form" onSubmit={addingAdmin}>
                                <div className="row">
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" name="first_name" required />
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" name="last_name" required />
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                        <input type="email" className="form-control" name="email" required />
                                    </div>
                                    {/* <div className="col-md-6 mt-md-0 mt-3"> <label>Phone Number</label>
                                        <input type="tel" className="form-control" name="mobile" /> </div> */}
                                </div>
                                <div className=" my-md-2 my-3 pt-2"> <label className="me-2">Role</label>
                                    <select id="sub" name="role_id" required>
                                        <option value="" selected hidden>Choose Option</option>
                                        {
                                            userPrivileges.map((role, i) => (
                                                <option key={i} value={role.ID} className="text-capitalize">{role.rank}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className={userResponseSuccess ? "text-success" : "text-danger"}>{userResponseMessage}</div>
                                <div className="d-flex justify-content-center">
                                    {
                                        usersSmallLoader ?
                                            <GlobalLoader />
                                            :
                                            <button type="submit" className="btn editBtn mt-3 px-5">Add</button>
                                    }
                                </div>

                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default Administrators;